var fileDownload = window.fileDownload;

function DownloadController($scope, SETTINGS, $http, $timeout, $sce, $log) {
  this.download = () => {
    var id = encodeURIComponent(this.job._id);
    var _downloadBaseUrl = SETTINGS.apiEndpoint + 'jobs/download/';
    var _tokenUrl = _downloadBaseUrl + 'token/' + id;

    $http.post(_tokenUrl).then(function successCb(response) {
      $timeout(function() {
        fileDownload(_downloadBaseUrl + encodeURIComponent(response.data), {
          successCallback: function  successCbFn(/* url*/) {
          },
          failCallback: function failCbFn(/* responseHtml, url, error*/) {
            // todo: have node.js send some html here
            // $ocModal.open({template: '<div>My content</div>'});
            alert('Sorry your download failed!');
          },
        });
      }, 0, false);
    }, function errCb(response) {
      // alert('Sorry your download failed!');
      $log.error(response);
    });
  };
}
angular.module('sq.jobs.download.controller', [])
.controller('DownloadController', DownloadController);
